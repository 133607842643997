:root {
  --shadow-color: 286deg 36% 56%;
}

.loading-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1031;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.loading-page__container__img img {
  max-width: 8vw;
}

@-webkit-keyframes scale-animation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-animation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.scale-animation {
  -webkit-animation: scale-animation 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate both;
  animation: scale-animation 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate both;
}

a.btn-home {
  color:
    /*#ffffff*/
    #282c34 !important;
  font-size: 18px;
  font-weight: 400;
  border-color: transparent !important;
  box-shadow: none !important;
}

div.btn-user>button {
  color:
    /*#ffffff*/
    #282c34 !important;
  font-weight: 400;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.txt-justify {
  font-size: 18px;
  text-align: justify;
}

.txt-center {
  font-size: 18px;
  text-align: center;
}

#collapsible-nav-dropdown~div {
  left: auto;
  right: 0;
}

.login {
  background: #e2e8eb;
}

.login,
.pageResponse {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.pageResponse {
  background-image: none;
}

.pageResponse .card {
  max-width: 450px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pageResponse .card .btn.btn-success {
  --bs-btn-bg: #62b6b7;
  --bs-btn-border-color: #62b6b7;
  --bs-btn-disabled-bg: #62b6b7;
  --bs-btn-disabled-border-color: #62b6b7;
  --bs-btn-hover-bg: #08c394;
  --bs-btn-hover-border-color: #08c394;
  --bs-btn-active-bg: #08c394;
  --bs-btn-active-border-color: #08c394;
  font-weight: 500;
}

.pageResponse .card .btn.btn-secondary {
  --bs-btn-bg: #bebebe;
  --bs-btn-border-color: #bebebe;
  --bs-btn-disabled-bg: #bebebe;
  --bs-btn-disabled-border-color: #bebebe;
  --bs-btn-hover-bg: #a2b5bb;
  --bs-btn-hover-border-color: #a2b5bb;
  --bs-btn-active-bg: #a2b5bb;
  --bs-btn-active-border-color: #a2b5bb;
  font-weight: 500;
}

.pageResponse .icon-logo {
  border-radius: 100%;
  margin: auto;
  margin-top: -56px;
  background: #eff5f5;
  height: 100px;
  width: 100px;
  border: solid 2px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageResponse b {
  font-weight: 500;
}

.center {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: rgb(255, 255, 255);
  padding: 30px 20px 30px 20px;
  width: 320px;
  border-radius: 5px;
}

.btn.btn-primary {
  --bs-btn-bg: rgb(50, 159, 231);
  --bs-btn-border-color: rgb(50, 159, 231);
  --bs-btn-hover-bg: rgb(41, 110, 149);
  --bs-btn-hover-border-color: rgb(41, 110, 149);
  --bs-btn-active-bg: rgb(41, 110, 149);
  --bs-btn-active-border-color: rgb(41, 110, 149);
  --bs-btn-disabled-bg: rgb(50, 159, 231);
  --bs-btn-disabled-border-color: rgb(50, 159, 231);
}

.observado.bg-dark {
  background-color: rgb(53, 123, 163) !important;
}

.no.valido.bg-dark {
  background-color: #dc3545 !important;
}

.proceso.bg-danger {
  background-color: #f65d22 !important;
}

.login-form-title {
  font-size: 24px;
  color: rgb(71, 71, 71);
  font-weight: 400;
  text-align: center;
}

nav.navbar {
  padding: 0;
}

nav.navbar>div {
  padding-top: 15px;
  padding-bottom: 15px;
}

nav.navbar>div:last-child {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

nav.fixed-top+.page-content {
  margin-top: 140px;
}

nav.fixed-top+.page-content.page-colaborador {
  width: 100%;
}

nav.shadow {
  box-shadow: 0 0.25rem 0.8rem rgba(0, 0, 0, 0.08) !important;
}

nav.fixed-top .navbar-brand span {
  font-family: "Roboto", sans-serif;
}

.MuiTablePagination-root p {
  margin: 0;
}

.rm-input-text input {
  border-right: none;
}

.rm-input-text>span.rm-icon {
  background: none !important;
  z-index: 9;
  margin-left: -4em !important;
  border-left: none;
  border-right: none;
}

.rm-input-text>span:last-child {
  border-left: var(--bs-border-width) var(--bs-border-color);
}

.modal-fullscreen .modal-header button:nth-child(2) {
  margin-left: auto;
  margin-right: 2rem;
}

.modal-fullscreen .modal-header button:nth-child(3) {
  margin-left: 0;
  margin-right: 2rem;
}

.modal-fullscreen .modal-header button:last-child {
  margin-left: 4rem;
}

.fade.modal-backdrop.show:nth-last-child(2) {
  z-index: 1055;
}

.dash-med .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.Mui-selected {
  font-weight: 600;
  overflow: visible;
}

.dash-med .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon {
  overflow: visible !important;
}

.dash-med .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.Mui-selected:nth-child(1) {
  color: rgb(220, 53, 69);
}

.dash-med .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.Mui-selected:nth-child(2) {
  color: rgb(234, 178, 9);
}

.dash-med .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.Mui-selected:nth-child(3) {
  color: rgb(5, 135, 84);
}

.dash-med .MuiSvgIcon-root:nth-child(2) {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
  color: rgb(0, 0, 0, 0.6);
}

.dash-med .MuiTabs-indicator {
  background-color: rgba(224, 224, 224, 1) !important;
}

.dash-med .MuiTablePagination-toolbar {
  justify-content: center;
}

.dash-med .MuiTablePagination-toolbar>.MuiTablePagination-spacer {
  display: none;
}

.dash-med .MuiFab-root.MuiFab-extended {
  position: fixed;
  right: 3.5vw;
  bottom: 4vh;
  background-color: #157347;
  border-color: #146c43;
  color: #fff;
  font-size: 1rem;
  text-transform: none;
}

.dash-med .MuiFab-root.MuiFab-extended svg {
  font-size: 1.25rem;
}

.dash-med .documentos-indispensables {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.dash-med button#welcome {
  margin-bottom: -80px;
  border-radius: 20px;
  padding: 8px 12px;
  z-index: 9;
  position: relative;
}

.dash-med .link-especial {
  background-color: #5c636a !important;
  border-color: #565e64 !important;
  color: #fff !important;
  border-radius: 20px !important;
  font-size: 0.8rem;
  text-transform: none;
  text-decoration: none;
  padding: 2px 8px;
}

.link-descripcion {
  text-transform: none;
  font-weight: 500;
  color: #000000 !important;
}

.MuiPopover-paper {
  font-size: 14px;
  line-height: 1.15rem;
  padding: 10px;
  /*background-color: #f4f7ff;*/
}

.MuiTableRow-root.file-selected {
  background-color: rgba(0, 0, 0, 0.15);
}

.MuiTableSortLabel-root .MuiTableSortLabel-icon {
  display: none;
}

@media (min-width: 1000px) and (max-width: 2000px) {

  .page-content.container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {

  .page-content.container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 98%;
  }
}

.revs input.form-check-input {
  opacity: 0.25;
  border: solid 0.1px;
}

.revs input.form-check-input:checked {
  background-color: #000000;
  opacity: 1;
  border: none;
}

.modal-fullscreen .modal-title {
  max-width: 63vw;
}

.react-viewer-footer {
  bottom: 3rem !important;
}

#containerImg .react-viewer-close.react-viewer-btn {
  display: none;
}

.dash-med .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected {
  background-color: transparent !important;
}

#imagen-comunicado img {
  max-height: calc(100vh - 110px);
  max-width: calc(98vw - 0.6rem);
}

#imagen-comunicado .btn-close {
  filter: invert(1);
  opacity: 1;
  transform: translateX(50%);
}

#imagen-comunicado .btn-close:focus {
  box-shadow: none !important;
}

#imagen-comunicado .modal-header {
  border-bottom: none;
  padding-top: 5px;
}

#imagen-comunicado .modal-content {
  background: transparent !important;
  border: none !important;
  width: auto;
  margin: auto;
}

.criticos,
.criticos p {
  margin-bottom: 0.25rem;
}

@media (max-width: 1380px) {
  .modal-fullscreen .modal-title {
    max-width: 55vw;
  }

  .modal-fullscreen .modal-header button:nth-child(2),
  .modal-fullscreen .modal-header button:nth-child(3) {
    margin-right: 1rem;
  }

  .modal-fullscreen .modal-header button:last-child {
    margin-left: 4rem;
  }
}

@media (max-width: 1500px) {
  .modal-fullscreen .modal-title {
    max-width: 58vw;
  }
}

@media (min-width: 1200px) {
  nav.fixed-top+.page-content {
    width: 85%;
  }
}

@media (max-width: 700px) {
  .dash-med .MuiPaper-root {
    padding-left: 1px;
    padding-right: 1px;
  }

  .dash-med .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    max-width: 40vw;
  }

  .dash-med .MuiTableContainer-root thead tr th {
    top: -2px;
  }

  .loading-page__container__img img {
    max-width: 40vw;
  }

  .dash-med .MuiTableContainer-root {
    max-height: calc(100vh - 270px);
  }

  .dash-med .MuiFab-root.MuiFab-extended {
    right: 1vw;
    bottom: 1vh;
  }

  .dash-med button#welcome {
    margin-bottom: -10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .dash-med .documentos-indispensables {
    text-align: center;
    margin-bottom: 0px;
  }
}

/* 
Imagen del Doctor imgDoctor
*/
#imgDoctor {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: all 0.5s ease;
}

#icono:hover {
  transform: scale(1.1);
  transition: all 0.8s ease;
}

#iconoInterno,
#iconoInterno2:hover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00a884;
  color: #fff;
}

#iconoInterno:hover,
#iconoInterno2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #00a884;
}

@media screen and (min-width: 1200px) {
  #imgDoctor {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .spaceImage {
    width: calc(100% - 230px);
  }
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  #imgDoctor {
    position: absolute;
    opacity: 0.3;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

@media screen and (max-width: 800px) {
  #icono {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  #imgDoctor {
    position: relative;
    opacity: 0.2;
    justify-content: center;
    align-items: center;
  }

  #imgDoctor img {
    width: 100%;
    height: auto;
    position: absolute;
  }

}

#detalleCargaMasiva,
#PasosReportesDwl>div {
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0.25rem 0.8rem rgba(0, 0, 0, 0.264);
  padding: 20px;
}

@media screen and (max-width: 700px) {

  #detalleCargaMasiva,
  #PasosReportesDwl {
    height: auto;
  }
}

@media screen and (min-width: 1601px) {

  #detalleCargaMasiva,
  #PasosReportesDwl {
    margin-top: -10px !important;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1201px) {

  #detalleCargaMasiva {
    margin-top: -45px !important;
  }

  #PasosReportesDwl {
    margin-top: -60px !important;
  }

  #detalleCargaMasiva p,
  #PasosReportesDwl p {
    margin-bottom: 0 !important;
  }

  #imgDoctor img {
    max-width: 260px;
  }
}

.customCheckbox>input {
  border-color: black;
}

#refresh:hover {
  rotate: 360deg;
  transition: all 0.8s ease;
}

#navbar {
  background: rgb(255, 255, 255);
  background: linear-gradient(83deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 22%,
      rgba(0, 161, 225, 0.453) 77%);
  box-shadow: 0 0.25rem 0.8rem rgba(94, 93, 93, 0.17);
  margin-bottom: 20px;
}

#navbar #navbar-brand {
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.5s ease;
}

.hello {
  font-size: 1.2rem;
  display: flex;
}

@media screen and (max-width: 700px) {
  #navbar #navbar-brand {
    font-size: 1rem;
  }

  #navbar #navbar-brand img {
    max-width: 100% !important;
  }

  .hello {
    display: none !important;
  }
}